import { toast, ToastOptions } from "react-toastify";

export type ToastType = "success" | "error" | "info" | "warning" | "default";

export function useToastUtils() {
  const showToast = ({
    type,
    message,
  }: {
    type: ToastType;
    message: string;
  }) => {
    const options = {
      position: "top-right" as ToastOptions["position"],
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    switch (type) {
      case "success":
        toast.success(message, options);
        break;
      case "error":
        toast.error(message, options);
        break;
      case "info":
        toast.info(message, options);
        break;
      case "warning":
        toast.warning(message, options);
        break;
      default:
        toast(message, options);
    }
  };
  return { showToast };
}
