import { useCallback, useState } from 'react';
import { fetchAudiosApi, IAudio } from '../rest_ client';
import { useLoader } from './use-loader';
import { useToastUtils } from './use-toast-utils';
import { usePagination } from './use-pagination';
import { useAtomValue, useSetAtom } from 'jotai';
import { refreshAudiosAtom, setRefreshAudiosAtom } from '../state/user';

export function useFetchAudios() {
  const [audios, setAudios] = useState<IAudio[]>([]);
  const {  totalItems, totalPages, currentPage, setTotalItems, setTotalPages, pageSize, onChangePage } =
    usePagination();
  const { isLoading, setIsLoading } = useLoader();
  const { showToast } = useToastUtils();
  const refreshAudios = useAtomValue(refreshAudiosAtom)
  const setRefreshAudios = useSetAtom(setRefreshAudiosAtom)

  const fetchAudios = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data, totalItems, totalPages } = await fetchAudiosApi({ limit: pageSize, page: currentPage });
      setRefreshAudios(false)
      setAudios(data);
      setTotalItems(totalItems);
      setTotalPages(totalPages);
      setIsLoading(false);
      showToast({ type: 'success', message: 'Audios fetched successfully' });
    } catch (error) {
      setRefreshAudios(false)
      setIsLoading(false);
      console.log(error);
      showToast({ type: 'error', message: 'Failed to fetch audios' });
    }
  }, [pageSize, currentPage, refreshAudios]);

  return { audios, fetchAudios, isLoading, totalItems, totalPages, currentPage, pageSize, onChangePage };
}
