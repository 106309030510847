/* eslint-disable react/no-unescaped-entities */
import { ClockIcon } from '@heroicons/react/24/outline';
import { AudioFilesTable } from '../AudioFilesTable';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const SummaryContent = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col items-start justify-start w-full border border-gray-300 rounded-3xl px-6 pt-6 pb-4 bg-white">
        <h3 className="text-faint-black font-plus-jakarta text-2xl font-bold">Welcome to Mwendo Speech</h3>
        <p className="text-gray-600 font-normal text-sm font-plus-jakarta w-[618px] mb-8"></p>
        <Button
          classes={{
            root: `!bg-faint-black  !text-white font-medium !text-sm !font-plus-jakarta
               !px-6 !py-3.5  !border !border-gray-300 !rounded-[14px] w-[285px] !normal-case  `,
          }}
          onClick={() => {
            navigate('/transcribe');
          }}
        >
          Transcribe
        </Button>
      </div>
      <div className="flex flex-col w-full items-start justify-start mt-8">
        <div className="flex flex-row items-center justify-start w-full gap-2 mb-6">
          <ClockIcon className="w-8 h-8 text-black" />
          <h3 className="font-plus-jakarta text-2xl font-bold ">Latest Uploaded files</h3>
        </div>
        <AudioFilesTable />
      </div>
    </>
  );
};
