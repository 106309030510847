import { Button, CircularProgress } from '@mui/material';
import ModalForm from '../ModalForm';
import OutlineInput from '../OutlineInput';
import { Controller, useForm } from 'react-hook-form';
import { useToastUtils } from '../../hooks/use-toast-utils';
import { useLoader } from '../../hooks/use-loader';
import { updateRadioAPI } from '../../rest_ client';
import { useSetAtom } from 'jotai';
import { setRefreshAudiosAtom } from '../../state/user';

export type EditRadioProps = {
  name: string;
  url: string;
};

export type EditRadioFormProps = {
  id: string;
  name: string;
  url: string;
  onHandleClose: () => void;
  open: boolean;
};

export const EditRadioForm = ({ name: defaultName, url: defaultUrl, onHandleClose, open, id }: EditRadioFormProps) => {
  const { showToast } = useToastUtils();
  const setRefreshAudios = useSetAtom(setRefreshAudiosAtom);
  const { isLoading, setIsLoading } = useLoader();
  const onSubmit = (data: EditRadioProps) => {
    if (!data.url || !data.name) {
      showToast({
        type: 'error',
        message: 'Please fill all the fields',
      });
    } else {
      setIsLoading(true);
      updateRadioAPI(id, data)
        .then((response) => {
          if (response) {
            showToast({
              type: 'success',
              message: 'Radio updated successfully',
            });
            setIsLoading(false);
            onHandleClose();
            setRefreshAudios(true);
          }
        })
        .catch((_) => {
          setIsLoading(false);
          showToast({ type: 'error', message: 'Failed to update radio' });
        });
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<EditRadioProps>({
    defaultValues: {
      name: defaultName,
      url: defaultUrl,
    },
  });
  return (
    <ModalForm open={open} onHandleClose={onHandleClose}>
      <h3 className="text-faint-black font-plus-jakarta text-2xl font-bold mb-4">Welcome file upload manager</h3>
      <form className="flex flex-col w-full items-start" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col items-start justify-start w-[700px] gap-3">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <OutlineInput label={'Name'} value={field.value} onHandleChange={(e) => field.onChange(e.target.value)} />
            )}
          />

          <Controller
            name="url"
            control={control}
            render={({ field }) => (
              <OutlineInput
                label={'URL'}
                value={field.value}
                type="url"
                onHandleChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
        </div>
        <div className="flex  flex-row w-full items-center justify-end gap-6.5 mt-6.5">
          <Button
            className="!w-33.75 !box-border !font-plus-jakarta !px-5.25 !py-3.5 !capitalize !text-input-field !text-header"
            onClick={onHandleClose}
          >
            Cancel
          </Button>

          <Button
            className=" !box-border !font-plus-jakarta !text-white  !capitalize !bg-pale-blue !text-sm !px-5.25 !py-3.5 !min-w-[150px]  "
            classes={{
              root: `!text-white font-medium !text-xs !font-plus-jakarta
                     !border !border-gray-300 !rounded-[14px] !normal-case `,
            }}
            type="submit"
            disabled={isSubmitting || isLoading}
          >
            {!isLoading ? (
              'Save'
            ) : (
              <CircularProgress
                size={23}
                classes={{
                  root: '!text-white',
                }}
              />
            )}
          </Button>
        </div>
      </form>
    </ModalForm>
  );
};
