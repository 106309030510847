import React from "react";
import { LayoutWrapper } from "../Common";
import { LeftPanel } from "./left-panel";
import { RightPanel } from "./right-panel";
import { Outlet } from "react-router-dom";
import { PageLoader } from "../PageLoader";
import { useFetchUser } from "../../hooks/use-fetch-user";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Dashboard = () => {
  const { fetchUser, isLoading, isAuthenticated } = useFetchUser();

  React.useEffect(() => {
    fetchUser();
  }, []);

  return (
    <LayoutWrapper>
      <ToastContainer />
      {!isLoading && isAuthenticated ? (
        <div className="flex flex-row items-start-justify-start w-full h-full max-h-full min-w-full  bg-gray-100 ">
          <LeftPanel />
          <RightPanel>
            <Outlet />
          </RightPanel>
        </div>
      ) : (
        <PageLoader />
      )}
    </LayoutWrapper>
  );
};
