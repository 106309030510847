import { RotatingLines, } from "react-loader-spinner";

export const PageLoader = () => {
  return (
      <div className="flex flex-col justify-center items-center w-full min-w-full h-full min-h-full bg-gray-100">
        <RotatingLines
          visible={true}
          strokeColor="#0A0F1F"
          strokeWidth="5"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
          width="100"
        />
      </div>
  );
};
