import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./Components/Login";
import { Dashboard } from "./Components/Dashboard";
import { SummaryContent } from "./Components/SummaryContent";
import { ViewAudio } from "./Components/ViewAudio";
import { Radios } from "./Components/Radios";
import { ViewRadio } from "./Components/ViewRadio";
import { Transcribe } from "./Components/Transcribe";

export const CustomRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />}>
        <Route index element={<SummaryContent />} />
        <Route path="audios/:audioId" element={<ViewAudio />} />
        <Route path="radios" element={<Radios />} />
        <Route path="radios/:radioId" element={<ViewRadio />} />
        <Route path="transcribe" element={<Transcribe />} />
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes>
  );
};
