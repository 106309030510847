import { Modal, Paper } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

export type ModalFormProps = {
  open: boolean;
  onHandleClose: () => void;
  children: React.ReactNode;
  isTop?: boolean;
};

export default function ModalForm({ open, onHandleClose, children, isTop = true }: ModalFormProps) {
  return (
    <Modal className="!outline-none !border-none" open={open} onClose={onHandleClose}>
      <div
        className={clsx('flex flex-col items-start justify-start pl-[380px] w-full h-full pr-10', {
          'pt-20': isTop,
        })}
      >
        <Paper className="w-full !shadow-none !border !border-gray-300 !rounded-3xl !px-10 !pt-6 !pb-4 !bg-white min-h-[400px]">
          <div className="flex flex-col">{children}</div>
        </Paper>
      </div>
    </Modal>
  );
}
