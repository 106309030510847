import React from 'react';
import { OutlinedInput } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import clsx from 'clsx';

export type IOutlinedInput = {
  children?: any;
  label?: string;
  type?: string;
  onHandleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  value?: string;
  endAdornment?: React.ReactNode;
  placeholder?: string;
  rounded?: boolean;
};

export default function OutlineInput({ label, type, onHandleChange, value, endAdornment,placeholder, rounded }: IOutlinedInput) {
  const [focused, setFocused] = useState<boolean>(false);
  return (
    <div className="w-full">
      {label && <p className="text-faint-black font-inter text-sm mb-2.5">{label}</p>}
      <OutlinedInput
        notched={false}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        type={type || 'text'}
        onChange={(event) => onHandleChange(event)}
        classes={{
          root: clsx('w-full w-100 bg-white', {
            'rounded-sm': true,
            '!rounded-3xl': rounded,
          }),
          notchedOutline: clsx({
         
            '!border-gray-300': !focused,
            '!border-gray-500': focused,
           
          }),
          input: '!text-sm !text-gray-800 !px-4  !py-4 !font-inter',
        }}
        value={value}
        endAdornment={endAdornment ? endAdornment : null}
        placeholder={placeholder}
      />
    </div>
  );
}
