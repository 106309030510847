import React from "react";
import { LayoutWrapper } from "../Common";
import OutlineInput from "../OutlineInput";
import { Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { login, setAuthToken } from "../../rest_ client";
import { useTimers } from "../../hooks/use-timers";
import { useToastUtils } from "../../hooks/use-toast-utils";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLoader } from "../../hooks/use-loader";

interface ILoginProps {
  email: string;
  password: string;
}

function Login() {
  const navigate = useNavigate();
  const { delay } = useTimers();
  const { showToast } = useToastUtils();
  const { isLoading, setIsLoading } = useLoader();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ILoginProps>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit: SubmitHandler<ILoginProps> = (data) => {
    setIsLoading(true);
    login({ email: data.email, password: data.password })
      .then((response) => {
        if (response) {
          setAuthToken(response.accessToken);
          showToast({ type: "success", message: "Login Successful" });
          setIsLoading(false);
          delay(1000).then(() => {
            navigate("/");
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showToast({ type: "error", message: "Login Failed" });
        console.log(error);
      });
  };

  return (
    <LayoutWrapper>
      <ToastContainer />
      <div className="flex flex-col w-full h-full min-h-full justify-center items-center">
        <form
          className="flex flex-col w-full h-full min-h-full justify-center items-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div
            className="flex flex-col w-[720px] rounded-[28px]
  border border-gray-300 bg-white overflow-hidden px-20 py-20 "
          >
            <Title>Get Started</Title>
            <Paragraph>Welcome to Audio Tool</Paragraph>
            <div className="w-full mb-4">
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <OutlineInput
                    label={"E-mail"}
                    value={field.value}
                    onHandleChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </div>
            <div className="w-full mb-10">
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <OutlineInput
                    label={"Password"}
                    type="password"
                    value={field.value}
                    onHandleChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </div>
            <div className="flex">
              <Button
                classes={{
                  root: `!bg-faint-black !w-full !text-white font-semibold !text-sm font-inter !px-6 !py-4 !font-inter !border !border-gray-300 !rounded-[28px]  `,
                }}
                type="submit"
              >
                {isSubmitting || isLoading ? (
                  <CircularProgress size={25} />
                ) : (
                  "Login"
                )}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </LayoutWrapper>
  );
}

export default Login;

export const Title = ({ children }: { children: React.ReactNode }) => {
  return (
    <h2 className="text-4xl font-bold mb-2 font-inter text-faint-black">
      {children}
    </h2>
  );
};

export const Paragraph = ({ children }: { children: React.ReactNode }) => {
  return <p className="text-lg font-inter mb-4 text-faint-black">{children}</p>;
};
