import DataTable, { ITableColumn } from '../DataTable';
import React, { memo, useState } from 'react';
import { useFetchRadios } from '../../hooks/use-fetch-radios';
import { useNavigate } from 'react-router-dom';
import { IRadio } from '../../rest_ client';
import { EditRadioForm } from '../EditRadio';

export const RadiosListTable = memo(function RadiosListTable() {
  const [radio, setRadio] = useState<IRadio | null>(null);
  const navigate = useNavigate();
  const {
    fetchRadios,
    isLoading,
    radios,
    totalItems,
    totalPages,
    currentPage,
    pageSize,
    onChangePage,
    searchValue,
    onSearch,
  } = useFetchRadios();

  React.useEffect(() => {
    fetchRadios();
  }, [fetchRadios]);

  const tableColumns: ITableColumn[] = [
    {
      name: 'Created at',
      dataKey: 'createdAt',
    },
    {
      name: 'Name',
      dataKey: 'name',
    },

    {
      name: 'Action',
      dataKey: 'action',
    },
  ];
  return (
    <>
      <DataTable
        columns={tableColumns}
        isLoading={isLoading}
        showSearch={true}
        data={radios}
        onClickViewAction={(id) => {
          navigate(`/radios/${id}`);
        }}
        onClickEditAction={(radio) => {
          setRadio(radio);
        }}
        {...{
          totalItems,
          totalPages,
          currentPage,
          pageSize,
          onChangePage,
          searchValue,
          onSearch: (value) => onSearch(value),
        }}
      />
      {radio && (
        <EditRadioForm
          {...{
            url: radio.audioFile,
            name: radio.name,
            id: radio.id,
            open: Boolean(radio),
            onHandleClose: () => {
              setRadio(null);
            },
          }}
        />
      )}
    </>
  );
});
