import React from 'react';
import { Avatar, Button, CircularProgress } from '@mui/material';
import logo from '../../assets/images/profile.jpeg';
import { HomeIcon, MicrophoneIcon } from '@heroicons/react/24/solid';
import { atom, useAtomValue } from 'jotai';
import { authenticatedUserAtom } from '../../state/user';
import { Link } from 'react-router-dom';
import { useLogout } from '../../hooks/use-logout';

const profileAtom = atom((get) => {
  const { email } = get(authenticatedUserAtom);
  return { email };
});

export const LeftPanel = () => {
  const { email } = useAtomValue(profileAtom);
  const {logoutUser, isLoading}  = useLogout()

  return (
    <div className="flex flex-col items-start justify-between w-[330px] h-full max-h-full border-r border-gray-300 px-6 py-8">
      <div className="flex flex-col items-start justify-start w-full gap-8">
        {/* <div className="flex flex-row  items-center justify-end w-full">
          <Avatar
            alt="Profile picture"
            src={logo}
            sx={{ width: 50, height: 50, borderRadius: '9px' }}
            className="rounded-[9px]"
            variant="square"
          />
        </div> */}
        <div className="flex flex-row items-center justify-between w-full">
          <Link to="/">
            <div
              className="flex flex-col items-start justify-start gap-2 w-[129px]
       border-gray-200 rounded-2xl bg-white px-4 py-[15px]"
            >
              <div className="flex flex-col justify-center items-center w-[30px] h-[30px] rounded-lg bg-pale-blue/10">
                <HomeIcon className="w-4.5 h-4.5 text-pale-blue" />
              </div>
              <h5 className="font-plus-jakarta text-base leading-5 font-medium text-faint-black">Home</h5>
            </div>
          </Link>
          <Link to="/radios">
            <div
              className="flex flex-col items-start justify-start gap-2 w-[129px]
       border-gray-200 rounded-2xl bg-white px-4 py-[15px]"
            >
              <div className="flex flex-col justify-center items-center w-[30px] h-[30px] rounded-lg bg-pale-blue/10">
                <MicrophoneIcon className="w-4.5 h-4.5 text-pale-blue" />
              </div>
              <h5 className="font-plus-jakarta text-base leading-5 font-medium text-faint-black">Radios</h5>
            </div>
          </Link>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start w-full bg-faint-black py-9.5 px-8 rounded-3xl">
        <h4 className="text-white font-plus-jakarta font-bold text-lg">Email</h4>
        <p className="font-normal font-plus-jakarta text-sm text-pale-gray leading-[18px]">{email}</p>
        <Button
          classes={{
            root: `!bg-white !w-full !text-faint-black font-medium !text-sm font-inter
             !px-6.5 !py-2.5 !font-plus-jakarta border-none !rounded-2.5 !normal-case !mt-2`,
          }}
          onClick={() => {
           logoutUser()
          }}
        >
          {isLoading?  <CircularProgress size={25}/>: " Sign out" }
         
        </Button>
      </div>
    </div>
  );
};
