import { useNavigate } from 'react-router-dom';
import DataTable, { ITableColumn } from '../DataTable';
import { useFetchAudios } from '../../hooks/use-fetch-audios';
import React, { memo } from 'react';

export const AudioFilesTable = memo(function AudioFilesTable() {
  const navigate = useNavigate();
  const { fetchAudios, isLoading, audios, totalItems, totalPages, currentPage, pageSize, onChangePage } =
    useFetchAudios();

  React.useEffect(() => {
    fetchAudios();
  }, [fetchAudios]);

  const tableColumns: ITableColumn[] = [
    {
      name: 'Created at',
      dataKey: 'createdAt',
    },
    {
      name: 'Title',
      dataKey: 'title',
    },
    {
      name: 'Description',
      dataKey: 'description',
    },
    // {
    //   name: 'Audio File',
    //   dataKey: 'audioFile',
    // },

    {
      name: 'Action',
      dataKey: 'action',
    },
  ];
  return (
    <DataTable
      columns={tableColumns}
      isLoading={isLoading}
      data={audios}
      onClickViewAction={(id) => {
        navigate(`/audios/${id}`);
      }}
      {...{
        totalItems,
        totalPages,
        currentPage,
        pageSize,
        onChangePage,
      }}
    />
  );
});
