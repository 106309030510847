import { Button } from "@mui/material";
import { ReactComponent as FileIcon } from "../../assets/icons/file.svg";
import { AttachFile } from "@mui/icons-material";
export type FileUploadProps = {
  name: string;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, name: string) => void;
  value: File | null;
  placeholder?: string;
};

export default function FileUploadInput({
  label,
  name,
  onChange,
  value,
  placeholder,
}: FileUploadProps) {
  const fileName = value ? value.name : null;
  let fileText = "";
  if (fileName) {
    if (fileName.length <= 45) {
      fileText = fileName;
    } else {
      fileText = fileName.slice(0, 30) + ".".repeat(5) + fileName.slice(-10);
    }
  }

  const inputName = name;

  return (
    <div
      className="w-full flex flex-col flex-1"
      onDrop={(event) => event.preventDefault()}
    >
      <p className="text-faint-black font-inter text-sm mb-2.5">{label}</p>
      <div>
        <input
          className={""}
          style={{ display: "none" }}
          id={`raised-button-file}-${name}`}
          onChange={(event) => onChange(event, inputName)}
          type="file"
        />
        <label htmlFor={`raised-button-file}-${name}`}>
          <Button
            className="!flex !flex-row !justify-start !items-center !gap-4 !px-6 !py-5.5  !bg-pale-blue/10 !rounded-3xl !border !border-pale-blue !border-dashed !h-[100px] !box-border "
            component="div"
          >
            <div className="flex flex-col justify-center items-center !w-14 !h-14 rounded-full bg-white box-border">
              {!fileName ? (
                <FileIcon />
              ) : (
                <AttachFile className="!text-pale-blue" />
              )}
            </div>
            <div className="flex flex-col  flex-1 justify-center gap-0.75">
              <p className="font-inter font-medium text-label text-blue capitalize">
                {!fileName ? "Upload Audio" : "Audio uploaded"}
              </p>
              <p className="font-inter font-light text-sm text-gray-700 capitalize">
                {!fileName ? "Drop your file here or browse" : fileText}
              </p>
            </div>
          </Button>
        </label>
      </div>
      <p className="font-inter font-light italic text-gray-500 text-xs capitalize mt-2">
        {placeholder || "Supported Files AAC, MP3, OGG"}
      </p>
    </div>
  );
}
