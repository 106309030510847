import { Button, CircularProgress, Switch } from '@mui/material';
import { useState } from 'react';
import OutlineInput from '../OutlineInput';
import TextAreaInput from '../TextAreaInput';
import FileUploadInput from '../FileUploadInput';
import { Controller, useForm } from 'react-hook-form';
import { useToastUtils } from '../../hooks/use-toast-utils';
import { useLoader } from '../../hooks/use-loader';
import { saveAudioAPI } from '../../rest_ client';
import { useSetAtom } from 'jotai';
import { setRefreshAudiosAtom } from '../../state/user';
import { useTranscribe } from '../../hooks/use-transcribe';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import React from 'react';
import { useNavigate } from 'react-router-dom';
export type AddAudioProps = {
  title: string;
  description: string;
  transcript?: string;
};

export const Transcribe = () => {
  const [audioFile, setAudioFile] = useState<File | null>(null);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [audioURL, setAudioURL] = useState<string | null>('');
  const { showToast } = useToastUtils();
  const { isLoading, setIsLoading } = useLoader();
  const setRefreshAudios = useSetAtom(setRefreshAudiosAtom);
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const { transcript, onTranscribe, isLoading: isTranscribing } = useTranscribe();
  const audioRef = React.useRef<HTMLAudioElement>(null);
  const navigate = useNavigate()
  const submitTranscription = () => {
    if (!(audioFile || audioBlob)) {
      showToast({
        type: 'error',
        message: 'Please fill all the fields',
      });
    } else {
      const formData = new FormData();
      if (showUpload) {
        if (audioFile) {
          formData.append('file', audioFile);
        }
      } else {
        if (audioBlob) {
          const file = new File([audioBlob as Blob], `${new Date().getTime()}.${audioBlob.type}`, {
            type: audioBlob.type,
          });
          formData.append('file', file);
        }
      }

      setIsLoading(true);
      onTranscribe(formData)
        .then((response) => {
          console.log(response);
          setIsLoading(false);
        })
        .catch((_) => {
          setIsLoading(false);
          showToast({ type: 'error', message: 'Failed to upload audio' });
        });
    }
  };

  const onSubmit = (data: AddAudioProps) => {
    if (!(audioFile || audioBlob) || !data.title || !data.description) {
      showToast({
        type: 'error',
        message: 'Please fill all the fields',
      });
    } else {
      const formData = new FormData();
      formData.append('title', data.title);
      formData.append('description', data.description);
      formData.append('audio_source', 'upload')
      if (showUpload) {
        if (audioFile) {
          formData.append('file', audioFile);
        }
      } else {
        if (audioBlob) {
          const file = new File([audioBlob as Blob], `${new Date().getTime()}.${audioBlob.type}`, {
            type: audioBlob.type,
          });
          formData.append('file', file);
        }
      }

      formData.append('transcript', data.transcript ?? '');

      setIsLoading(true);
      saveAudioAPI(formData)
        .then((response) => {
          if (response) {
            showToast({
              type: 'success',
              message: 'Audio uploaded successfully',
            });
            setIsLoading(false);
            setRefreshAudios(true);
            navigate('/')
          }
        })
        .catch((_) => {
          setIsLoading(false);
          showToast({ type: 'error', message: 'Failed to upload audio' });
        });
    }
  };
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<AddAudioProps>({
    defaultValues: {
      title: '',
      description: '',
    },
  });

  const recorderControls = useAudioRecorder();

  const addAudioElement = (blob: any) => {
    const audioURL = URL.createObjectURL(blob);
    if (audioRef.current) {
      audioRef.current.src = audioURL;
      audioRef.current.controls = true;
    }
    setAudioBlob(blob);
    setAudioURL(audioURL);
  };
  return (
    <div className="flex flex-col w-full gap-6 justify-start items-start ">
      <div className="flex flex-col items-start justify-start w-full border border-gray-300 rounded-3xl px-6 pt-6 pb-4 bg-white">
        <h3 className="text-faint-black font-plus-jakarta text-2xl font-bold">Welcome to Mwendo Speech</h3>
      </div>
      <div className="flex flex-col items-start gap-4 justify-start w-full border border-gray-300 rounded-3xl px-6 pt-6 pb-4 bg-white">
        <h3 className="text-faint-black font-plus-jakarta text-2xl font-bold mb-4">Mwendo Speech transcribe</h3>
        <form className="flex flex-col w-full items-start" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col items-start justify-start w-[700px] gap-3">
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <OutlineInput
                  label={'Title'}
                  value={field.value}
                  onHandleChange={(e) => field.onChange(e.target.value)}
                />
              )}
            />

            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextAreaInput
                  name="description"
                  label={'Description'}
                  value={field.value}
                  onHandleChange={(e) => field.onChange(e.target.value)}
                />
              )}
            />

            <div className="flex flex-col gap-2 w-full items-start justify-start">
              <div className="flex flex-row items-center justify-start gap-2">
                <Switch
                  checked={showUpload}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setShowUpload(event.target.checked)}
                />
                <p className="text-faint-black font-plus-jakarta text-base font-medium">
                  {showUpload ? 'Upload Audio' : 'Record Audio'}
                </p>
              </div>
              {showUpload ? (
                <FileUploadInput
                  {...{
                    label: '',
                    value: audioFile,
                    onChange: (event) => {
                      setAudioFile(event?.target?.files ? event?.target?.files[0] : null);
                    },
                    name: 'audio',
                  }}
                />
              ) : (
                <>
                  <AudioRecorder
                    onRecordingComplete={(blob) => {
                      addAudioElement(blob);
                    }}
                    recorderControls={recorderControls}
                    downloadFileExtension="mp3"
                  />
                  {audioURL && (
                    <audio controls ref={audioRef}>
                      <source src={audioURL} />
                    </audio>
                  )}
                </>
              )}
            </div>
            {transcript && (
              <Controller
                name="transcript"
                control={control}
                defaultValue={transcript}
                render={({ field }) => (
                  <TextAreaInput
                    name="transcript"
                    label={'Transcript'}
                    value={field.value}
                    onHandleChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            )}
          </div>
          <div className="flex  flex-row w-full items-center justify-end gap-6.5 mt-6.5">
            {(audioBlob || audioFile) && (
              <Button
                className=" !box-border !font-plus-jakarta !text-white  !capitalize !bg-pale-blue !text-sm !px-5.25 !py-3.5 !min-w-[150px]  "
                classes={{
                  root: `!text-white font-medium !text-xs !font-plus-jakarta
                     !border !border-gray-300 !rounded-[14px] !normal-case `,
                }}
                onClick={submitTranscription}
                disabled={isTranscribing}
              >
                {!isTranscribing ? (
                  'Transcribe'
                ) : (
                  <CircularProgress
                    size={23}
                    classes={{
                      root: '!text-white',
                    }}
                  />
                )}
              </Button>
            )}
            <Button
              className=" !box-border !font-plus-jakarta !text-white  !capitalize !bg-pale-blue !text-sm !px-5.25 !py-3.5 !min-w-[150px]  "
              classes={{
                root: `!text-white font-medium !text-xs !font-plus-jakarta
                     !border !border-gray-300 !rounded-[14px] !normal-case `,
              }}
              type="submit"
              disabled={isSubmitting || isLoading}
            >
              {!isLoading ? (
                'Save'
              ) : (
                <CircularProgress
                  size={23}
                  classes={{
                    root: '!text-white',
                  }}
                />
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
