import { useCallback, useState } from 'react';
import { fetchRadioApi, IRadio } from '../rest_ client';
import { useLoader } from './use-loader';
import { useToastUtils } from './use-toast-utils';

export function useFetchRadio({ radioId }: { radioId: string }) {
  const [radio, setRadio] = useState<IRadio | null>(null);
  const { isLoading, setIsLoading } = useLoader();
  const { showToast } = useToastUtils();
 

  const fetchRadio = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await fetchRadioApi(radioId as string);
      setRadio(data);
      setIsLoading(false);
      showToast({ type: 'success', message: 'Radio fetched successfully' });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showToast({ type: 'error', message: 'Failed to fetch radio' });
    }
  }, [radioId]);

  return { isLoading, radio, fetchRadio };
}
