/* eslint-disable react/no-unescaped-entities */
import React, { memo } from "react";
export const RightPanel = memo(function RightPanel({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <div className="flex flex-1 flex-col items-center justify-start min-h-full max-h-full overflow-y-auto h-full py-12 ">
      <div className="flex flex-col items-start justify-start w-[calc(100vw-430px)] ">
        {children}
      </div>
    </div>
  );
});
