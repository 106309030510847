import axios from 'axios';
import { format } from 'date-fns';

const httpInstance = axios.create({
  baseURL: 'https://backend.mwendospeech.com',
});

export function getAuthToken() {
  const token = localStorage.getItem('X_AUTH_TOKEN');
  return token;
}

export function setAuthToken(token?: string) {
  if (token) {
    // Set the token in localStorage
    localStorage.setItem('X_AUTH_TOKEN', token);
    httpInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    // Remove the token from localStorage
    localStorage.removeItem('X_AUTH_TOKEN');
    delete httpInstance.defaults.headers.common['Authorization'];
  }
}

const token = localStorage.getItem('X_AUTH_TOKEN');
if (token) {
  setAuthToken(token);
}

export async function login({ email, password }: { email: string; password: string }) {
  const response = await httpInstance({
    url: '/login',
    method: 'POST',
    data: {
      email,
      password,
    },
  });
  return { accessToken: response?.data?.access_token };
}

export async function fetchAuthenticatedUser() {
  const response = await httpInstance({
    url: '/me',
    method: 'GET',
  });
  return {
    id: response?.data?._id,
    email: response?.data?.email,
    createdAt: response?.data?.created_at,
    updatedAt: response?.data?.updated_at,
  };
}

export async function logoutUserApi() {
  await httpInstance({
    url: '/logout',
    method: 'GET',
  });
  return;
}

export type IAudio = {
  id: string;
  title: string;
  description: string;
  url: string;
  transcript: string;
  createdAt: string;
  updatedAt: string;
};

export type IRadio = {
  id: string;
  name: string;
  audioFile: string;
  createdAt: string;
  updatedAt: string;
};

export type FetchAudioResponse = {
  data: IAudio[];
  totalItems: number;
  totalPages: number;
};

export type FetchRadioResponse = {
  data: IRadio[];
  totalItems: number;
  totalPages: number;
};

export type IFilterProps = {
  limit: number;
  page: number;
  searchValue?: string;
};

export async function fetchAudiosApi({ limit, page }: IFilterProps): Promise<FetchAudioResponse> {
  const response = await httpInstance({
    url: '/audios',
    method: 'GET',
    params: {
      limit,
      page,
    },
  });
  return {
    data:
      response?.data?.items?.map((audio: any) => ({
        id: audio._id,
        title: audio.title,
        description: audio.description,
        audioFile: audio.url,
        createdAt: format(new Date(audio.created_at), 'yyyy-MM-dd HH:mm:ss'),
        updatedAt: format(new Date(audio.updated_at), 'yyyy-MM-dd HH:mm:ss'),
      })) || [],
    totalItems: response?.data?.total_items || 0,
    totalPages: response?.data?.total_pages || 0,
  };
}

export async function saveAudioAPI(formData: FormData) {
  const response = await httpInstance({
    url: '/audios',
    method: 'POST',
    data: formData,
  });
  return response.data;
}

export async function fetchAudioApi(id: string): Promise<IAudio> {
  const response = await httpInstance({
    url: `/audios/${id}`,
    method: 'GET',
  });
  return {
    id: response?.data?._id,
    title: response?.data?.title,
    description: response?.data?.description,
    transcript: response?.data?.transcript,
    url: response?.data?.url,
    createdAt: format(new Date(response?.data?.created_at), 'yyyy-MM-dd HH:mm:ss'),
    updatedAt: format(new Date(response?.data?.updated_at), 'yyyy-MM-dd HH:mm:ss'),
  };
}

export async function fetchRadiosApi({ limit, page, searchValue }: IFilterProps): Promise<FetchRadioResponse> {
  const response = await httpInstance({
    url: '/radios',
    method: 'GET',
    params: {
      limit,
      page,
      search_value: searchValue,
    },
  });
  return {
    data:
      response?.data?.items?.map((radio: any) => ({
        id: radio._id,
        name: radio.name,
        audioFile: radio.url,
        createdAt: format(new Date(radio.created_at), 'yyyy-MM-dd HH:mm:ss'),
        updatedAt: format(new Date(radio.updated_at), 'yyyy-MM-dd HH:mm:ss'),
      })) || [],
    totalItems: response?.data?.total_items || 0,
    totalPages: response?.data?.total_pages || 0,
  };
}

export async function fetchRadioApi(id: string): Promise<IRadio> {
  const response = await httpInstance({
    url: `/radios/${id}`,
    method: 'GET',
  });
  return {
    id: response?.data?._id,
    name: response?.data?.name,
    audioFile: response?.data?.url,
    createdAt: format(new Date(response?.data?.created_at), 'yyyy-MM-dd HH:mm:ss'),
    updatedAt: format(new Date(response?.data?.updated_at), 'yyyy-MM-dd HH:mm:ss'),
  };
}

export async function updateRadioAPI(id: string, data: { name: string; url: string }) {
  const response = await httpInstance({
    url: `/radios/${id}`,
    method: 'PUT',
    data: data,
  });
  return response.data;
}

export async function transcribeAPI(
  formData: FormData
): Promise<{ results: { transcript: string; file_name: string }[] }> {
  const response = await httpInstance({
    url: '/transcribe/file',
    method: 'POST',
    data: formData,
  });
  return response.data;
}
