import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, Skeleton } from '@mui/material';
import clsx from 'clsx';
import TablePagination from '../TablePagination';
import { EyeIcon, PencilSquareIcon } from '@heroicons/react/24/solid';
import { MagnifyingGlassIcon, TrashIcon } from '@heroicons/react/24/outline';
import { AudioPlayerUI } from '../AudioPlayer';
import OutlineInput from '../OutlineInput';

export type ITableColumn = {
  name: string;
  dataKey: string;
};

export type ITableData = {
  [key: string]: string | number | React.ReactNode;
};

export type DataTableProps = {
  columns: ITableColumn[];
  data: ITableData[];
  hideHeaderSection?: boolean;
  onClickViewAction?: (id: string | number) => void;
  isLoading?: boolean;
  totalItems?: number;
  totalPages?: number;
  currentPage?: number;
  pageSize?: number;
  onChangePage?: (page: number) => void;
  onClickEditAction?: (row: any) => void;
  onSearch?: (value: string) => void;
  searchValue?: string;
  showSearch?: boolean;
};

export default function DataTable({
  columns,
  data,
  onClickViewAction,
  isLoading,
  totalItems = 0,
  totalPages = 0,
  currentPage = 0,
  pageSize = 0,
  onChangePage,
  onClickEditAction,
  searchValue,
  onSearch,
  showSearch = false,
}: DataTableProps) {
  const totalEntries = totalItems;
  const startEntry = pageSize * (currentPage - 1) + 1;
  const endEntry = Math.min(pageSize * currentPage, totalItems);

  const dataKeys: string[] = columns.map(({ dataKey }: ITableColumn) => dataKey);
  return (
    <div className="flex flex-col gap-4 w-full flex-1">
      {showSearch && (
        <div className="flex flex-row items-center justify-end w-full">
          <div className="w-[400px]">
            <OutlineInput
              value={searchValue}
              onHandleChange={(e) => onSearch && onSearch(e.target.value)}
              endAdornment={<MagnifyingGlassIcon className="w-5 h-5 text-faint-black" />}
              placeholder='Search ....'
              rounded={true}
            />
          </div>
        </div>
      )}
      <Paper className={clsx('w-full !shadow-none !rounded-3xl overflow-hidden')}>
        <div className="flex w-full">
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                {columns.map(({ name }, index) => (
                  <TableCell
                    className={clsx(
                      '!font-plus-jakarta !text-sm !font-normal !text-white !border-0 !bg-faint-black !text-left !pl-6'
                    )}
                    key={index}
                  >
                    {name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading &&
                data.map((item: ITableData, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {dataKeys.map((dataKey, index) => (
                      <TableCell
                        className={clsx(
                          '!border-0 !border-gray-300 !font-plus-jakarta !text-sm   !font-normal !pl-6 !py-2.5 !text-gray-500',
                          {
                            '!border-b': data.length - 1 !== rowIndex,
                          }
                        )}
                        key={index}
                      >
                        {!['action', 'audioFile'].includes(dataKey) && item[dataKey]}
                        {dataKey === 'action' && (
                          <div className="flex flex-row items-center gap-2">
                            {/* <ActionButton
                              Icon={<TrashIcon className="w-5 h-5 text-red-400" />}
                              
                            /> */}
                            <ActionButton
                              Icon={<EyeIcon className="w-6 h-6 text-pale-blue" />}
                              onClick={() => {
                                onClickViewAction && onClickViewAction(item.id as string);
                              }}
                            />
                            {onClickEditAction && <ActionButton
                              Icon={<PencilSquareIcon className="w-5 h-5 text-pale-blue" />}
                              onClick={() => {
                                onClickEditAction && onClickEditAction(item);
                              }}
                            />}
                          </div>
                        )}
                        {dataKey === 'audioFile' && <AudioPlayerUI audioFile={item[dataKey] as string} />}
                      </TableCell>
                    ))}
                    {isLoading && (
                      <TableRow>
                        <TableCell colSpan={columns.length} className="!text-center">
                          <Skeleton animation="wave" width="100%" height={40} />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </Paper>
      <TablePagination
        {...{
          totalPages,
          currentPage,
          totalEntries,
          startEntry,
          endEntry,
          onChangePage,
        }}
      />
    </div>
  );
}

export const ActionButton = ({ Icon, onClick }: { Icon?: React.ReactNode; onClick: () => void }) => {
  return (
    <IconButton
      classes={{
        root: `!bg-pale-blue/10 !rounded-lg !py-2 !px-2 w-10 !max-w-[40px]`,
      }}
      onClick={onClick}
    >
      {Icon}
    </IconButton>
  );
};
