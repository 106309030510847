import { Pagination, PaginationItem } from '@mui/material';

export type TablePaginationProps = {
  totalPages?: number;
  currentPage?: number;
  totalEntries?: number;
  startEntry?: number;
  endEntry?: number;
  onChangePage?: (page: number) => void;
};

export default function TablePagination({
  totalPages = 50,
  currentPage = 1,
  totalEntries = 57,
  startEntry = 1,
  endEntry = 10,
  onChangePage,
}: TablePaginationProps) {
  return (
    <div className="flex flex-row justify-between items-center h-15.25">
      <p className="!text-sm !font-plus-jakarta !font-normal !text-faint-black">
        Showing {startEntry} to {endEntry} of {totalEntries} entries
      </p>
      <Pagination
        className="!font-plus-jakarta"
        count={totalPages}
        defaultPage={currentPage}
        onChange={(event, page) => onChangePage && onChangePage(page)}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            classes={{
              root: '!font-plus-jakarta !text-sm !font-bold border  border-gray-300 bg-white !w-8 !h-8 !rounded-sm !bg-white ',
              selected: '!border !border-pale-blue !text-pale-blue ',
              icon: '!text-gray-500 !disabled:text-gray-400',
            }}
          />
        )}
      />
    </div>
  );
}
