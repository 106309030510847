import { useCallback, useState } from 'react';
import { fetchAudioApi, IAudio } from '../rest_ client';
import { useLoader } from './use-loader';
import { useToastUtils } from './use-toast-utils';

export function useFetchAudio({ audioId}: { audioId: string}) {
  const [audio, setAudio] = useState<IAudio | null>(null);
  const { isLoading, setIsLoading } = useLoader();
  const { showToast } = useToastUtils();

  const fetchAudio = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await fetchAudioApi(audioId as string);
      setAudio(data);
      setIsLoading(false);
      showToast({ type: 'success', message: 'Audio fetched successfully' });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showToast({ type: 'error', message: 'Failed to fetch audio' });
    }
  }, [audioId]);

  return { audio, fetchAudio, isLoading };
}
