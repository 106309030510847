import { OutlinedInput } from "@mui/material";
import { ChangeEvent, useState } from "react";
import clsx from "clsx";

export type TexFieldProps = {
  label: string;
  name: string;
  type?: string;
  value?: string | number;
  onHandleChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
};

export default function TextAreaInput({
  label,
  type,
  name,
  value,
  onHandleChange,
}: TexFieldProps) {
  const [focused, setFocused] = useState<boolean>(false);
  return (
    <div className="w-full">
      <p className="text-faint-black font-inter text-sm mb-2.5">{label}</p>
      <OutlinedInput
        notched={false}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        multiline
        type={type || "text"}
        autoComplete="off"
        name={name}
        value={value}
        classes={{
          root: "w-full !p-0",
          notchedOutline: clsx({
            "rounded-sm": true,
            "!border-gray-300": !focused,
            "!border-gray-500": focused,
          }),
          input: "!text-sm !text-gray-800 !px-4  !py-4 !font-inter",
        }}
        onChange={onHandleChange}
      />
    </div>
  );
}
