import { useAtomValue, useSetAtom } from "jotai";
import { fetchAuthenticatedUser } from "../rest_ client";
import { useLoader } from "./use-loader";
import { isAuthenticatedAtom, setAuthenticatedUserAtom } from "../state/user";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

export function useFetchUser() {
  const { isLoading, setIsLoading } = useLoader();
  const setAuthenticatedUser = useSetAtom(setAuthenticatedUserAtom);
  const isAuthenticated = useAtomValue(isAuthenticatedAtom);
  const navigate = useNavigate();

  const fetchUser = useCallback(async () => {
    try {
      setIsLoading(true);
      const { id, email, createdAt, updatedAt } =
        await fetchAuthenticatedUser();
      setAuthenticatedUser({
        isAuthenticated: true,
        id,
        email,
        createdAt,
        updatedAt,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setAuthenticatedUser({
        isAuthenticated: false,
      });
      navigate("/login");
    }
  }, []);
  return { fetchUser, isLoading, isAuthenticated };
}
