import { atom } from "jotai";

export type IUser = {
  isAuthenticated: boolean;
  id?: string;
  email?: string;
  createdAt?: string;
  updatedAt?: string;
};

export const authenticatedUserAtom = atom<IUser>({
  isAuthenticated: false,
});

export const setAuthenticatedUserAtom = atom(null, (_get, set, user: IUser) => {
  set(authenticatedUserAtom, user);
});

export const isAuthenticatedAtom = atom(
  (get) => get(authenticatedUserAtom).isAuthenticated,
);


export const  refreshAudiosAtom = atom<boolean>(false);

export const setRefreshAudiosAtom = atom(null, (_get, set, value: boolean) => {
  set(refreshAudiosAtom, value);
} );

export const refreshRadiosAtom = atom<boolean>(false);

export const setRefreshRadiosAtom = atom(null, (_get, set, value: boolean) => {
  set(refreshRadiosAtom, value);
});



