import { useSetAtom } from 'jotai';
import { setAuthenticatedUserAtom } from '../state/user';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { logoutUserApi, setAuthToken } from '../rest_ client';
import { useLoader } from './use-loader';

export function useLogout() {
  const setAuthenticatedUser = useSetAtom(setAuthenticatedUserAtom);
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useLoader();

  const logoutUser = useCallback(async () => {
    setIsLoading(true);
    try {
      await logoutUserApi();
      setAuthenticatedUser({
        isAuthenticated: false,
      });
      setIsLoading(false);
      setAuthToken(undefined);
      navigate('/login');
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setAuthenticatedUser({
        isAuthenticated: false,
      });
      setAuthToken(undefined);
    }
  }, []);
  return { logoutUser, isLoading };
}
