import { debounce } from "@mui/material";
import { useCallback, useState } from "react";


export function usePagination(){
    const [totalItems, setTotalItems] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize] = useState<number>(8);
    const [searchValue, setSearchValue] = useState<string>("");

    const onChangePage = useCallback((page: number) => {
        setCurrentPage(page);
      }, []);

    const onSearch = useCallback((value: string) => {
        setSearchValue(value);
    }
    , []);

    const debounceOnSearch = debounce(onSearch, 200);
    return {
        totalItems,
        totalPages,
        currentPage,
        setTotalItems,
        setTotalPages,
        setCurrentPage,
        pageSize,
        onChangePage,
        searchValue,
        onSearch: debounceOnSearch,
    }
}