export const PlayIcon = () => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4688 11.7019C15.3838 11.7892 15.0627 12.1624 14.7637 12.4694C13.0103 14.4001 8.43647 17.5602 6.04252 18.5248C5.67895 18.6798 4.75978 19.0079 4.26868 19.029C3.79809 19.029 3.3495 18.9206 2.92144 18.7009C2.38782 18.3939 1.95975 17.9109 1.72519 17.3405C1.5742 16.9448 1.33964 15.7605 1.33964 15.7394C1.10508 14.4437 0.976073 12.3385 0.976073 10.012C0.976073 7.79537 1.10508 5.77588 1.29712 4.46066C1.31911 4.43959 1.55367 2.96786 1.81022 2.46374C2.2808 1.54278 3.19997 0.970947 4.18365 0.970947L4.26868 0.970947C4.90931 0.993519 6.25655 1.56536 6.25655 1.58642C8.5215 2.55253 12.9898 5.55768 14.7857 7.5546C14.7857 7.5546 15.2914 8.06774 15.5113 8.38827C15.8544 8.84875 16.0244 9.41909 16.0244 9.98942C16.0244 10.626 15.8324 11.2189 15.4688 11.7019Z"
        fill="#29353D"
      />
    </svg>
  );
};
