/* eslint-disable react/no-unescaped-entities */
import { Button, CircularProgress } from '@mui/material';
import { AudioPlayerUI } from '../AudioPlayer';
import { useEffect, useState } from 'react';
import { useFetchAudio } from '../../hooks/use-fetch-audio';
import { useParams } from 'react-router-dom';
import { PageLoader } from '../PageLoader';

export const ViewAudio = () => {
  const params = useParams();
  const [showTranscript] = useState<boolean>(false);
  const { audio, fetchAudio, isLoading } = useFetchAudio({ audioId: params.audioId as string });
  const loading = false;

  useEffect(() => {
    fetchAudio();
  }, [fetchAudio]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <div className="flex flex-col w-full gap-6 justify-start items-start ">
      <div className="flex flex-col items-start justify-start w-full border border-gray-300 rounded-3xl px-6 pt-6 pb-4 bg-white">
        <h3 className="text-faint-black font-plus-jakarta text-2xl font-bold">Welcome to Mwendo Speech</h3>
        <p className="text-gray-600 font-normal text-sm font-plus-jakarta w-[618px] mt-2"></p>
      </div>

      <div className="flex flex-col items-start gap-4 justify-start w-full border border-gray-300 rounded-3xl px-6 pt-6 pb-4 bg-white">
        <div className="flex flex-row items-center gap-6">
          <AudioPlayerUI showJumpControls showSkipControls fullWidth audioFile={audio?.url as string} />
        </div>
        <div className="flex flex-col items-start justify-start w-full">
          <h3 className="text-faint-black font-plus-jakarta text-base font-medium">Title</h3>
          <p className="text-gray-600 font-normal text-sm font-plus-jakarta max-w-[1000px]">{audio?.title}</p>
        </div>
        <div className="flex flex-col items-start justify-start w-full">
          <h3 className="text-faint-black font-plus-jakarta text-base font-medium">Description</h3>
          <p className="text-gray-600 font-normal text-sm font-plus-jakarta max-w-[1000px]">{audio?.description}</p>
        </div>
        {audio?.transcript && (
          <div className="flex flex-col items-start justify-start w-full">
            <h3 className="text-faint-black font-plus-jakarta text-base font-medium">Transcription</h3>
            {audio?.transcript}
          </div>
        )}
        {showTranscript && (
          <Button
            className=" !box-border !font-plus-jakarta !text-faint-black !capitalize !bg-pale-blue !text-sm !px-8 !py-3.5 !min-w-[150px]  "
            onClick={() => {
              /** */
            }}
            classes={{
              root: `!text-faint-black font-medium !text-xs !font-plus-jakarta
                   !border !border-gray-300 !rounded-[14px] !normal-case `,
            }}
          >
            {!loading ? (
              'Save transcription for this audio'
            ) : (
              <CircularProgress
                size={23}
                classes={{
                  root: '!text-white',
                }}
              />
            )}
          </Button>
        )}
      </div>
    </div>
  );
};
