import { useCallback, useState } from 'react';
import { fetchRadiosApi, IRadio } from '../rest_ client';
import { useLoader } from './use-loader';
import { useToastUtils } from './use-toast-utils';
import { usePagination } from './use-pagination';
import { useAtomValue, useSetAtom } from 'jotai';
import { refreshAudiosAtom, setRefreshAudiosAtom } from '../state/user';

export function useFetchRadios() {
  const [radios, setRadios] = useState<IRadio[]>([]);
  const { isLoading, setIsLoading } = useLoader();
  const { showToast } = useToastUtils();
  const {
    totalItems,
    totalPages,
    currentPage,
    setTotalItems,
    setTotalPages,
    pageSize,
    onChangePage,
    searchValue,
    onSearch,
  } = usePagination();
  const refreshAudios = useAtomValue(refreshAudiosAtom);
  const setRefreshAudios = useSetAtom(setRefreshAudiosAtom);
  const fetchRadios = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data, totalItems, totalPages } = await fetchRadiosApi({ limit: pageSize, page: currentPage, searchValue: searchValue?? undefined });
      setRefreshAudios(false);
      setRadios(data);
      setIsLoading(false);
      setTotalItems(totalItems);
      setTotalPages(totalPages);
      setIsLoading(false);
      showToast({ type: 'success', message: 'Radios fetched successfully' });
    } catch (error) {
      setRefreshAudios(false);
      setIsLoading(false);
      console.log(error);
      showToast({ type: 'error', message: 'Failed to fetch radios' });
    }
  }, [pageSize, currentPage, refreshAudios, searchValue]);

  return {
    radios,
    fetchRadios,
    isLoading,
    totalItems,
    totalPages,
    currentPage,
    pageSize,
    onChangePage,
    searchValue,
    onSearch,
  };
}
